import { Choice } from '@/types/base'
import { Individual, Entity, makeIndividual, makeEntity } from '@/types/people'
import { sum } from '@/utils/math'
import { dateToString } from '@/filters/texts'
import { distinctElements } from '@/utils/arrays'

export class AttendanceItem {
  constructor(
    public day: Date,
    public seanceName: string,
    public name: string,
    public isTotal: boolean,
    public subItems: Map<number, number> = new Map(),
    public entities: Map<number, number[]> = new Map()
  ) {
  }

  public addSubItem(groupBy: Choice, value: number) {
    this.subItems.set(groupBy.id, value)
  }

  public addEntities(groupBy: Choice, entities: number[]) {
    this.entities.set(groupBy.id, entities)
  }

  public total(): number {
    return sum(Array.from(this.subItems.values()))
  }

  public getLabel(): string {
    if (this.seanceName) {
      return this.seanceName
    } else {
      return dateToString(this.day) + (this.name ? (' ' + this.name) : '')
    }
  }

  public getValue(choice: Choice): number {
    return this.subItems.get(choice.id) || 0
  }

  public getEntities(choice: Choice): number[] {
    return this.entities.get(choice.id) || []
  }

  public allEntities(choice: Choice): number[] {
    let allEntities: number[] = []
    for (const entities of Array.from(this.entities.values())) {
      allEntities = allEntities.concat(entities)
    }
    return distinctElements(allEntities)
  }
}

export function makeAttendanceItem(jsonData: any = null, isTotal: boolean = false): AttendanceItem {
  if (!jsonData) {
    jsonData = {}
  }
  return new AttendanceItem(
    jsonData['seance__date'] || '',
    jsonData['seance__name'] || '',
    jsonData['name'] || '',
    isTotal
  )
}

export class AttendanceListItem {
  constructor(
    public individual: Individual,
    public entity: Entity,
    public daysCount: number,
    public halfDaysCount: number,
    public morningsCount: number,
    public lunchesCount: number,
    public afternoonsCount: number,
    public fixedFeeCount: number
  ) {
  }
}

export function makeAttendanceListItem(jsonData: any = null): AttendanceListItem {
  if (!jsonData) {
    jsonData = {}
  }
  return new AttendanceListItem(
    makeIndividual(jsonData.individual),
    makeEntity(jsonData.entity),
    jsonData['days_count'] || 0,
    jsonData['half_days_count'] || 0,
    jsonData['mornings_count'] || 0,
    jsonData['lunches_count'] || 0,
    jsonData['afternoons_count'] || 0,
    jsonData['fixed_fee_count'] || 0
  )
}

export class FamilyCompositionItem {
  constructor(
    public id: number,
    public name: string,
    public membersCount: number,
    public adultsCount: number,
    public childrenCount: number,
    public inscriptionsCount: number,
    public youthInscriptionsCount: number
  ) {
  }
}

export function makeFamilyCompositionItem(jsonData: any = null): FamilyCompositionItem {
  if (!jsonData) {
    jsonData = {}
  }
  return new FamilyCompositionItem(
    jsonData.id,
    jsonData.name,
    jsonData.members_count,
    jsonData.adults_count,
    jsonData.children_count,
    jsonData.inscriptions_count,
    jsonData.youth_inscriptions_count
  )
}
